import React, { useContext, useEffect, useRef } from 'react';
import refetchQueries from 'refetch-queries';

import {
  UserType,
  MonthlyScoreType,
  useUserMeQuery,
  useSubscribeNotificationCreatedSubscription,
  useDtWeeklyProgressQuery,
  DtWeeklyProgressQuery,
  useDailyTipperDataQuery,
  DailyTipperDataQuery,
  ClassRoomSessionDocument,
  useClassroomMembersLazyQuery,
  UserNotificationsQuery,
  UserMeDocument,
  useReadNotificationMutation,
  QuizSessionsOngoingDocument,
  QuizSessionsAnnouncedDocument,
  QuizSessionsFinishedDocument,
  GetActiveQuizDuelsDocument,
  QuizScoreDocument,
  GetCompletedQuizDuelsDocument,
  QuizTimeUsersDocument,
  QuizSessionsOnHomePageDocument,
  UserSubscriptionDocument,
} from '@/graphql';
import { NotifTypes, QueriesNamesEnum } from '../type';
import { NOTIFICATIONS_QUERY } from '@/apollo/queries';
import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from './snackbar-context';
import { ApolloError } from '@apollo/client';
import { useConfirmPayment } from '@/hooks/useConfirmPayment';
import { datadogRum } from '@datadog/browser-rum';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';

interface IUserContext {
  user?: UserType | null;
  monthlyScore?: MonthlyScoreType | null;
  dtWeek?: DtWeeklyProgressQuery;
  dtData?: DailyTipperDataQuery | null;
  contentAvailable?: boolean | null;
  error?: ApolloError;
  loading: boolean;
  joinedClassRooms: number | null | undefined;
}

export const UserContext =
  React.createContext<IUserContext | undefined>(undefined);

export const UserProvider: React.FC = (props) => {
  const { children } = props;
  const history = useHistory();
  const receivedNotifId = useRef<string | null>(null);
  const { setCustomSnack } = useSnackbarContext();
  const { handlePaymentConfirmation } = useConfirmPayment();
  const {
    isUserPremiumByPayment,
    isUserInAppPremium,
    isUserPremiumByCode,
    isUserPremiumByFriendship,
  } = useGetPremiumData();

  const dtData = useDailyTipperDataQuery({
    fetchPolicy: 'cache-and-network',
  });
  const me = useUserMeQuery();
  const dt = useDtWeeklyProgressQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (me.data?.me?.id) {
      const user = me.data?.me;
      const applicationType = window?.ReactNativeWebView
        ? 'app'
        : window.matchMedia('(display-mode: standalone)').matches
        ? 'pwa'
        : 'website';
      const premiumBy = isUserPremiumByPayment
        ? 'payment'
        : isUserInAppPremium
        ? 'in-app-payment'
        : isUserPremiumByCode
        ? 'code'
        : isUserPremiumByFriendship
        ? 'friendship'
        : null;

      datadogRum.setUser({
        id: user?.id,
        name: user.username!,
        email: user.email!,
        plan: user.isPremium,
        premiumBy,
        profession: user.profession?.title,
        speciality: user.speciality?.title,
        university: user.university?.title,
        gender: user.gender,
        dateOfBirth: user.dateOfBirth,
        applicationType,
      });
      datadogRum.startSessionReplayRecording();
    }
  }, [
    me,
    isUserPremiumByPayment,
    isUserInAppPremium,
    isUserPremiumByCode,
    isUserPremiumByFriendship,
  ]);

  const [getClassroomMembers] = useClassroomMembersLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [readNotification] = useReadNotificationMutation();

  useSubscribeNotificationCreatedSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      const message = subscriptionData.data?.notificationCreated;
      const notifData = JSON.parse(message?.data);
      console.log('notif message:', message);
      if (receivedNotifId.current === message?.id) return;
      receivedNotifId.current = message?.id;

      if (!notifData?.hidden) {
        const notifications: UserNotificationsQuery | undefined | null =
          client.readQuery({
            query: NOTIFICATIONS_QUERY,
          });
        const updatedNotifications = [
          ...(notifications?.notifications || []),
          message,
        ];
        client.writeQuery({
          query: NOTIFICATIONS_QUERY,
          data: {
            notifications: updatedNotifications,
          },
        });
      }

      if (notifData.type === NotifTypes.LEARNING_SESSION && notifData?.id) {
        refetchQueries(client, [
          {
            query: ClassRoomSessionDocument,
            variables: { sessionId: notifData?.id },
          },
        ]);
      }
      if (notifData.type === NotifTypes.SUBSCRIPTION) {
        if (
          (message?.id && history.location.pathname.includes('payment')) ||
          history.location.pathname.includes('network') ||
          history.location.pathname.includes('subscription')
        ) {
          const readNotificationWithError = async () => {
            try {
              await readNotification({
                variables: { id: message?.id },
              });
            } catch (error) {
              console.log(JSON.stringify(error));
            }
          };
          readNotificationWithError();
        }
        refetchQueries(client, [{ query: UserMeDocument }]);
      }
      if (notifData.type === NotifTypes.DUEL) {
        client.refetchQueries({
          updateCache(cache) {
            cache.evict({ fieldName: QueriesNamesEnum.ACTIVE_DUELS });
            cache.evict({ fieldName: QueriesNamesEnum.COMPLETED_DUELS });
            cache.evict({ fieldName: QueriesNamesEnum.MONTHLY_SCORE });
            cache.evict({ fieldName: QueriesNamesEnum.RANKING });
            cache.evict({ fieldName: QueriesNamesEnum.TOP_USERS });
          },
        });
        if (notifData?.learning_session_id) {
          getClassroomMembers({
            variables: {
              sessionId: notifData?.learning_session_id,
            },
          });
        }
      }
      if (
        notifData.type === NotifTypes.PAYMENT_REQUIRES_CONFIRMATION &&
        message
      ) {
        handlePaymentConfirmation(message);
      }
      if (notifData.type === NotifTypes.IN_APP_SUBSCRIPTION_CANCELING) {
        setCustomSnack({
          visibility: true,
          message: 'in-app-subscriptions.snackBars.snack-message-subs-canceled',
        });
        refetchQueries(client, [
          {
            query: UserSubscriptionDocument,
          },
        ]);
      }
      if (notifData.type === NotifTypes.QT_WAITING_FOR_FINISH) {
        refetchQueries(client, [
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ]);
      }
      if (notifData.type === NotifTypes.QT_FINISHED) {
        refetchQueries(client, [
          { query: QuizSessionsOngoingDocument },
          { query: QuizSessionsFinishedDocument },
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ]);
      }
      if (notifData.type === NotifTypes.QT_STARTED) {
        refetchQueries(client, [
          { query: QuizSessionsOngoingDocument },
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ]);
      }
      if (notifData.type === NotifTypes.QT_NEW) {
        refetchQueries(client, [
          { query: QuizSessionsAnnouncedDocument },
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ]);
      }
      if (notifData.type === NotifTypes.QT_USERS_COUNT) {
        const quizSessionId = notifData?.quiz_session_id;
        if (quizSessionId) {
          client.refetchQueries({
            updateCache(cache) {
              cache.evict({ fieldName: QueriesNamesEnum.QUIZTIME_SESSION });
            },
          });
        }
      }
      if (notifData.type === NotifTypes.QT_DUEL_STARTED) {
        const quizSessionId = notifData?.quiz_session_id;
        if (quizSessionId) {
          client.refetchQueries({
            updateCache(cache) {
              cache.evict({ fieldName: QueriesNamesEnum.ACTIVE_QUIZ_DUELS });
              cache.evict({ fieldName: QueriesNamesEnum.QUIZ_SESSION_MEMBERS });
            },
          });
        }
      }
      if (notifData.type === NotifTypes.QT_DUEL_FINISHED) {
        const quizSessionId = notifData?.quiz_session_id;
        if (quizSessionId) {
          client.refetchQueries({
            updateCache(cache) {
              cache.evict({ fieldName: QueriesNamesEnum.ACTIVE_QUIZ_DUELS });
              cache.evict({ fieldName: QueriesNamesEnum.QUIZ_DUEL_SCORE });
              cache.evict({ fieldName: QueriesNamesEnum.COMPLETED_QUIZ_DUELS });
              cache.evict({ fieldName: QueriesNamesEnum.QUIZ_SESSION_MEMBERS });
            },
          });
        }
      }
      if (notifData.type === NotifTypes.QT_SESSION_FREE_PLACE) {
        const quizSessionId = notifData?.quiz_session_id;
        if (quizSessionId) {
          client.refetchQueries({
            updateCache(cache) {
              cache.evict({ fieldName: QueriesNamesEnum.QUIZTIME_SESSION });
              cache.evict({ fieldName: QueriesNamesEnum.QUIZ_SESSION_MEMBERS });
            },
          });
        }
      }
      if (notifData.type === NotifTypes.QT_DUEL_IGNORED) {
        setCustomSnack({
          visibility: true,
          message: 'quiztime.snackBars.snack-message-ignored-duel',
          isTimerIcon: true,
        });
        const quizSessionId = notifData?.quiz_session_id;
        if (quizSessionId) {
          refetchQueries(client, [
            {
              query: GetActiveQuizDuelsDocument,
              variables: {
                quizSessionId,
              },
            },
            {
              query: QuizScoreDocument,
              variables: {
                quizSessionId,
              },
            },
            {
              query: GetCompletedQuizDuelsDocument,
              variables: {
                quizSessionId,
              },
            },
            {
              query: QuizTimeUsersDocument,
              variables: {
                quizSessionId,
              },
            },
          ]);
        }
      }
    },
  });

  return (
    <UserContext.Provider
      value={{
        user: me.data?.me as UserType | null | undefined,
        monthlyScore: me.data?.monthlyScore as MonthlyScoreType,
        dtWeek: dt.data,
        dtData: dtData.data,
        contentAvailable: !!me.data?.contentAvailable,
        joinedClassRooms: me.data?.joinedClassRooms,
        loading: me.loading,
        error: me.error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const state = useContext(UserContext);
  if (!state) throw new Error('UserProvider not found');
  return state;
};
