import { useEffect, useRef } from 'react';
import { ProfessionTypesEnum } from '@/type';
import { useUser } from '@/contexts/user-context';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { WebActionType } from '../../in-app-subscriptions/types';

export const useSendUserDataToNative = () => {
  const { user } = useUser();
  const {
    isUserPremium,
    isUserPremiumByPayment,
    isUserPremiumByCode,
    isUserPremiumByFriendship,
  } = useGetPremiumData();

  const userDataWasSentToNativeRef = useRef(false);

  useEffect(() => {
    if (!window?.ReactNativeWebView) return;

    userDataWasSentToNativeRef.current = true;
    const userType = user?.profession?.type?.type;
    const isSpecificProfession =
      user?.speciality?.title === 'Rheumatologie' &&
      userType === ProfessionTypesEnum.MedicalAssistant;
    const isUserStudent =
      userType === ProfessionTypesEnum.Student ||
      userType === ProfessionTypesEnum.Trainee ||
      userType === ProfessionTypesEnum.TraineeToNurse;
    const payload = {
      user,
      isUserStudent,
      isUserPremium,
      isUserPremiumByPayment,
      isUserPremiumByCode,
      isUserPremiumByFriendship,
      isPurchaseDisabled: isSpecificProfession,
    };
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: WebActionType.SendUserData,
        payload,
      })
    );
  }, [
    user,
    isUserPremium,
    isUserPremiumByPayment,
    isUserPremiumByCode,
    isUserPremiumByFriendship,
  ]);
};
