import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as Form from '@/components/form';
import SponsorBlock from '../sponsor-block';
import { CustomCodesType } from '@/type';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { useUserMeQuery } from '@/graphql';

interface UserFreemiumBlockProps {
  isUserPremium: boolean | null | undefined;
  isSubsStatusIncomplete: boolean | null | undefined;
  currentCode: CustomCodesType | null | undefined;
}
const UserFreemiumBlock: React.FC<UserFreemiumBlockProps> = ({
  isUserPremium,
  isSubsStatusIncomplete,
  currentCode,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { inAppPaymentEnabled } = useFeatureFlags();
  const { data: user } = useUserMeQuery();

  const handleActivatePremium = useCallback(() => {
    history.push({
      pathname: window?.ReactNativeWebView
        ? inAppPaymentEnabled || user?.me?.isUserForTest
          ? '/purchase-in-app-subscription'
          : '/'
        : '/choose-subscription',
      state: {
        prevPage: '/my-profile/network',
        isRedirectedFromNetwork: true,
      },
    });
  }, [history, inAppPaymentEnabled, user]);

  if (isUserPremium) {
    return null;
  }

  return (
    <>
      <Form.InfoField label={t('fields.current-membership.label')}>
        <Typography gutterBottom variant="subtitle1" component="span">
          {t('common.freemium')}
        </Typography>
        <br />

        {!isSubsStatusIncomplete && (
          <Typography variant="body1" color="textSecondary" component="span">
            {t(`common.freemium-info`)}
          </Typography>
        )}
      </Form.InfoField>
      <SponsorBlock code={currentCode} />
      {!isSubsStatusIncomplete && (
        <Box pt={7}>
          <Button
            fullWidth
            data-cy-activate-premium
            color="primary"
            variant="contained"
            onClick={handleActivatePremium}
          >
            {t('common.activate-premium')}
          </Button>
        </Box>
      )}
    </>
  );
};
export default UserFreemiumBlock;
