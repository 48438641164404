import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { CodeMode, CustomCodesType, SubscriptionStatus } from '@/type';
import { useUserNetworkCodesQuery, useUserSubscriptionQuery } from '@/graphql';
import { LS_KEY_NATIVE_OS_NAME } from '@/utils/constants';

export const useGetPremiumData = () => {
  const { data: codesData } = useUserNetworkCodesQuery();
  const { data: subsData } = useUserSubscriptionQuery();

  const currentCode = useMemo<CustomCodesType | undefined | null>(
    () =>
      codesData?.codes?.reduce((code, it) => {
        const now = DateTime.local();
        const start = DateTime.fromISO(it?.startDate);
        const finish = DateTime.fromISO(it?.finishDate).endOf('day');
        if (start < now && finish > now) {
          if (code && DateTime.fromISO(code?.finishDate) > finish) return code;
          return it;
        }
        return code;
      }, null),
    [codesData]
  );

  const isUserPremiumByCode = useMemo(
    () => currentCode?.codeMode?.valueOf() === CodeMode.Premium,
    [currentCode]
  );

  const isUserPremiumByPayment = useMemo(
    () =>
      subsData?.me?.customer?.subscription?.status ===
        SubscriptionStatus.ACTIVE ||
      subsData?.me?.customer?.subscription?.status ===
        SubscriptionStatus.TRIALING,
    [subsData]
  );

  const isUserPremiumByFriendship = useMemo(
    () => !!subsData?.me?.isPromotionPremium,
    [subsData]
  );
  const isUserInAppPremium = useMemo(
    () => !!subsData?.me?.isInAppPremium,
    [subsData]
  );

  const isUserPremium = useMemo(
    () =>
      (isUserPremiumByPayment && subsData?.me?.isPremium) ||
      isUserInAppPremium ||
      isUserPremiumByCode ||
      isUserPremiumByFriendship,
    [
      isUserPremiumByPayment,
      isUserInAppPremium,
      subsData,
      isUserPremiumByCode,
      isUserPremiumByFriendship,
    ]
  );

  const premiumFinishDate = useMemo(() => {
    return (
      subsData?.me?.inAppSubscription?.expirationDatetime ||
      subsData?.me?.customer?.subscription?.currentPeriodEnd ||
      currentCode?.finishDate ||
      subsData?.me?.promotionFinishDate
    );
  }, [subsData, currentCode]);

  const subscriptionType = (
    subsData?.me?.customer?.subscription?.subscriptionType ||
    subsData?.me?.inAppSubscription?.subscriptingType
  )?.toLowerCase();

  const isPlatformSuitableForSubscription = useMemo(() => {
    const subscriptionOriginStore =
      isUserInAppPremium && subsData?.me?.inAppSubscription?.store
        ? subsData.me.inAppSubscription.store
        : isUserPremiumByPayment
        ? 'STRIPE'
        : null;
    const nativeOSName = localStorage.getItem(LS_KEY_NATIVE_OS_NAME);
    if (nativeOSName === null) {
      return subscriptionOriginStore === 'STRIPE';
    }
    if (nativeOSName === 'android') {
      return subscriptionOriginStore === 'PLAY_STORE';
    }
    if (nativeOSName === 'ios') {
      return subscriptionOriginStore === 'APP_STORE';
    }
    return false;
  }, [subsData, isUserInAppPremium, isUserPremiumByPayment]);

  return {
    isUserPremium,
    isUserPremiumByPayment,
    isUserInAppPremium,
    isUserPremiumByCode,
    isUserPremiumByFriendship,
    premiumFinishDate,
    currentCode,
    isPlatformSuitableForSubscription,
    subscriptionType,
  };
};
